<template>
  <v-container fluid>
    <servers-table
      :headers="headers"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size)"
      @cancelDelete="cancelDelete($event)"
      @deleteServer="deleteRow($event)"
    />
  </v-container>
</template>

<script>
import { getServers, deleteServer } from "@/api/objects/server";
import serversTable from "@/views/objects/servers/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    serversTable,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "IP", value: "ip" },
        { text: "Port", value: "port" },
        { text: "Login", value: "login" },
        { text: "Authorization", value: "authorization" },
        { text: "Path", value: "path" },
        { text: "", value: "delete", sortable: false, align: "end" },
      ],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "objectserver") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getServers(newPage, size)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    cancelDelete(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
    },
    deleteRow(item) {
      const data = [];
      this.data.forEach(function (value) {
        if (item.id === value.id) {
          value.deleteMenu = false;
        }
        data.push(value);
      });
      this.data = [];
      this.$nextTick(() => {
        this.data = data;
      });
      deleteServer(item.id)
        .then((response) => {
          if (response.type === "success") {
            this.$toast.open({
              message: "Remote server was successfully deletes!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
          } else {
            Errors.show(response);
          }
          this.loadData(this.page, this.size);
        })
        .catch((e) => {
          Errors.show(e);
          const data = [];
          this.data.forEach(function (value) {
            if (item.id === value.id) {
              value.resetLoading = false;
            }
            data.push(value);
          });
          this.data = [];
          this.$nextTick(() => {
            this.data = data;
          });
        });
    },
  },
};
</script>
